<template>
  <b-row>
    <b-col md="4">
      <input-text
        ref="documento"
        v-model="documento"
        :label="$t('GERAL.DOCUMENTO')"
        :placeholder="$t('GERAL.DOCUMENTO')"
        @enter="filtrar"
      />
    </b-col>
    <b-col md="auto">
      <b-button
        variant="primary"
        class="botao-acao-filtro mb-3 w-100"
        @click="filtrar"
      >
        {{ $t('GERAL.FILTRAR') }}
      </b-button>
    </b-col>
    <b-col md="auto">
      <b-button
        variant="secondary"
        class="botao-acao-filtro mb-3 w-100"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

// Components:
import { InputText } from '@/components/inputs';

export default {
  components: {
    InputText,
  },
  data() {
    return {
      documento: '',
    };
  },
  methods: {
    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },
    filtrar() {
      if (!this.validarFiltro()) return;
      this.$emit('filtrar', this.documento);
    },
    limparFiltro() {
      this.documento = '';
    },
  },
};
</script>
