<template>
  <b-row>
    <b-col md="4">
      <input-select-search
        ref="tipoMovimento"
        v-model="form.tipoMovimento"
        :label="$t(`CONTABIL.TIPO_MOVIMENTO`)"
        :options="opcoes.tiposMovimento"
        :limparValidacao="resetarValidacao"
        required
      />
    </b-col>
    <b-col md="4">
      <input-number
        ref="valor"
        v-model="form.valor"
        :label="$t('CONTABIL.VALOR')"
        :limparValidacao="resetarValidacao"
        decimal
        required
      />
    </b-col>
    <b-col md="4">
      <input-text
        ref="descricaoLancamento"
        v-model="form.descricao"
        :label="$t('CONTABIL.DESCRICAO_LANCAMENTO')"
        :placeholder="$t('CONTABIL.DESCRICAO_LANCAMENTO')"
        :limparValidacao="resetarValidacao"
        required
      />
    </b-col>
    <b-col md="8">
      <input-select-search
        ref="planoConta"
        v-model="form.planoConta"
        :label="$t(`CONTABIL.PLANO_CONTA`)"
        :options="opcoes.planosConta"
        :limparValidacao="resetarValidacao"
        required
      />
    </b-col>
    <b-col md="auto">
      <b-button v-if="!edicao"
        variant="primary"
        class="botao-acao-filtro w-100 mb-3"
        @click="adicionarItem"
      >
        {{ $t('CONTABIL.ADICIONAR_LANCAMENTO') }}
      </b-button>

       <b-button v-else
        variant="primary"
        class="botao-acao-filtro w-100 mb-3"
        @click="editarItem"
      >
        {{ "Editar lançamento" }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// Utils & Aux:
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import { START_LOADING, STOP_LOADING } from '@/store/Store';

// Services:
// import DocumentoContabil from '@/common/services/lancamento-contabil/lancamento-contabil.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import PlanoContaService from '@/common/services/plano-conta/plano-conta.service.js';

//componentes
import { InputSelectSearch, InputNumber, InputText } from '@/components/inputs';

export default {
  props: {
    resetarValidacao: { type: Boolean, default: false },
    edicao:{type:Boolean, default: false},
    form: {
      type: Object,
      default: () => ({
        descricao: '',
        planoConta: '',
        valor: '',
        tipoMovimento: '',
      }),
    },
  },
  components: {
    InputNumber,
    InputSelectSearch,
    InputText,
  },
  data() {
    return {
      opcoes: {
        tiposMovimento: [],
        planosConta: [],
      },
      filtro: {
        exibir: false,
      },
    };
  },
  mounted() {
    this.getTipoMovimento();
    this.getPlanosConta();
  },
  methods: {
    getTipoMovimento() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-movimento')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row,
            text: row.descricao,
          }));

          this.opcoes.tiposMovimento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getPlanosConta() {
      this.$store.dispatch(START_LOADING);
      PlanoContaService.obterTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row,
            text: row.descricao,
          }));
          this.opcoes.planosConta = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    adicionarItem() {
      if (!this.validarFormulario()) return;
      this.$emit('adicionarDocumentoItem');
    },

    editarItem() {
      if (!this.validarFormulario()) return;
      this.$emit('adicionarDocumentoItem');
    },

    // FUNÇÕES FORMULARIO
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>
