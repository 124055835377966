import ApiService from '@/common/api/api.service';

const urlBaseDocumentoContabil = 'DocumentoContabil';

const DocumentoContabilService = {
  async criar(form) {
    let result = await ApiService.post(`${urlBaseDocumentoContabil}`, form);
    return result;
  },
  async atualizar(form) {
    let result = await ApiService.put(`${urlBaseDocumentoContabil}`, form);
    return result;
  },
  async excluir(itemId) {
    let result = await ApiService.delete(
      `${urlBaseDocumentoContabil}/${itemId}`
    );
    return result;
  },
  async obterPor(filtro, paginacao) {
    let { data } = await ApiService.get(
      `${urlBaseDocumentoContabil}/obter-por-filtro`,
      {
        params: {
          ...filtro,
          tamanhoDaPagina: paginacao.tamanhoDaPagina,
          numeroDaPagina: paginacao.numeroDaPagina,
        },
      }
    );
    return data;
  },
  async buscarCategorias() {
    let { data } = await ApiService.get(
      `enumeradores/categoria-documento-contabil`
    );
    return data.data;
  },
  async salvarDocumentoItem(form) {
    const { data } = await ApiService.post(
      `${urlBaseDocumentoContabil}/documento-contabil-item`,
      form
    );
    return data;
  },
  async atualizarDocumentoItem(form) {
    const { data } = await ApiService.put(
      `${urlBaseDocumentoContabil}/documento-contabil-item`,
      form
    );
    return data;
  },
  async excluirDocumentoItem(params) {
    let { data } = await ApiService.delete(
      `${urlBaseDocumentoContabil}/documento-contabil-item?documentoContabilItemId=${params.documentoContabilItemId}&DocumentoContabilId=${params.documentoContabilId}`
    );
    return data;
  },
  async obterDocumentosContabeisItensPorDocumentoContabilId(id) {
    const { data } = await ApiService.get(
      `${urlBaseDocumentoContabil}/obter-por-documento-contabil-id?id=${id}`
    );
    return data;
  },
};

export default DocumentoContabilService;
