<template>
  <modal
    id="modalLancamentosContabeis"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <!-- DADOS DO DOCUMENTO -->
    <div class="mb-4">
      <dados-documento
        :form="formulario"
        @refs="capturarRefs"
        @selecionarDocumentoOrigem="selecionarDocumentoOrigem"
      />
    </div>
    <!-- LISTA DE LANÇAMENTOS VINCULADOS -->
    <div>
      <h4>{{ $t('CONTABIL.LISTA_LANCAMENTOS_VINCULADOS') }}</h4>
      <dados-documento-item
        :form="formularioDocumentoItem"
        :resetarValidacao="resetarValidacao"
        @adicionarDocumentoItem="adicionarDocumentoItem"
        :edicao="edicao"
      />
      <tabela-documento
        :items="formulario.documentoContabeisItens"
        @removerDocumentoItem="removerDocumentoItem"
        @editarDocumentoItem="editarDocumentoItem"
      />
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Helpers
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
// Services
import LancamentoContabilService from '@/common/services/lancamento-contabil/lancamento-contabil.service.js';

// Components
import Modal from '@/components/modal/Modal.vue';
import DadosDocumento from './Dados';
import TabelaDocumento from './Tabela';
import DadosDocumentoItem from './DadosDocumentoItem';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: {
      type: Object,
      default: () => ({
        tipoLancamento: '',
        dataDocumento: '',
        dataMovimento: '',
        documentoOrigemCodigo: '',
        tipoTituloEnum: '',
        objetoReferenciaId: '',
        documentoContabeisItens: [],
      }),
    },
  },
  components: {
    Modal,
    DadosDocumento,
    TabelaDocumento,
    DadosDocumentoItem,
  },
  computed: {
    titulo() {
      return !this.form.id
        ? this.$t('GERAL.CADASTRAR')
        : this.$t('GERAL.EDITAR');
    },
  },
  data() {
    return {
      resetarValidacao: false,
      items: [{}],
      formulario: this.form,
      formularioDocumentoItem: {},
      refs: [],
      edicao: false,
    };
  },
  watch: {
    'formulario.tipoTituloEnum': {
      handler: 'atualizarObjetoReferencia',
      deep: true,
    },
    'formulario.documentoOrigemCodigo': {
      handler: 'atualizarObjetoReferencia',
      deep: true,
    },
    form: {
      handler(value) {
        let documentoContabeisItens =
          value.documentoContabeisItens?.map((item) => ({
            ...item,
            tipoMovimento: {
              value: {
                ...item.tipoMovimento,
                value: item.tipoMovimento,
                descricao: item.tipoMovimentoDescricao,
              },
            },
            planoConta: {
              value: {
                ...item.planoConta,
                value: item.planoContaId,
                descricao: item.planoConta.descricao,
              },
            },
          })) ?? [];
        this.formulario = { ...value, documentoContabeisItens };
      },
    },
    exibir: 'buscarDocumentosContabeisItens',
  },
  methods: {
    buscarDocumentosContabeisItens(value) {
      if (value)
        LancamentoContabilService.obterDocumentosContabeisItensPorDocumentoContabilId(
          this.form.id
        )
          .then(({ data }) => {
            this.formulario.documentoContabeisItens = data;
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
    },
    // FUNÇÕES DE VALIDAÇÃO E FECHAMENTO
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },

    fecharModal() {
      this.$emit('atualizarTabela');
      this.$emit('fechar');
    },

    capturarRefs(refs) {
      this.refs = refs;
    },

    // FUNÇÕES DE SALVAR E ATUALIZAR
    salvar() {
      if (!this.validarFormulario()) return;
      const documentosItensFormatados =
        this.formulario.documentoContabeisItens?.map((item) => ({
          ...item,
          planoContaId: item.planoConta.value.id,
          tipoMovimento: item.tipoMovimento.value.id,
        }));

      const dados = {
        ...this.formulario,
        categoria: this.formulario.categoriaId,
        documentoContabeisItens: documentosItensFormatados,
      };

      if (this.formulario.id) {
        return this.atualizar(dados);
      }
      this.criar(dados);
    },

    criar(dados) {
      this.$store.dispatch(START_LOADING);
      LancamentoContabilService.criar(dados)
        .then(({ data }) => {
          this.limparFormularios();
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    atualizar(dados) {
      this.$store.dispatch(START_LOADING);
      LancamentoContabilService.atualizar(dados)
        .then(({ data }) => {
          this.limparFormularios();
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    limparFormularios() {
      this.formulario = {
        dataDocumento: '',
        dataMovimento: '',
        documentoOrigemCodigo: '',
        tipoTituloEnum: '',
        objetoReferenciaId: '',
        documentoContabeisItens: [],
      };
      this.formularioDocumentoItem = {};
    },

    // FUNÇÕES DE SELEÇÃO E ATUALIZAÇÃO
    selecionarDocumentoOrigem(item) {
      this.formulario = {
        ...this.formulario,
        documentoOrigemCodigo: item.codigo,
        tituloOcorrenciaId: item.id,
      };
    },

    atualizarObjetoReferencia() {
      const { tipoTituloEnum, documentoOrigemCodigo } = this.formulario;
      if (tipoTituloEnum && documentoOrigemCodigo) {
        this.formulario.objetoReferenciaId = `${
          tipoTituloEnum == '0' ? 'CP' : 'CR'
        }${documentoOrigemCodigo}`;
      }
    },

    // FUNÇÕES DE ADIÇÃO E EDIÇÃO DE ITEM
    adicionarDocumentoItem() {
      if (this.formularioDocumentoItem?.id) {
        return this.atualizarDocumentoItem();
      } else if (this.formulario.id) {
        return this.salvarDocumentoItem();
      }
      this.adicionarDocumentoItemLocal();
      this.limparValidacaoFormItem();
    },

    limparValidacaoFormItem() {
      setTimeout(() => {
        this.resetarValidacao = true;
      }, 50);
      this.resetarValidacao = false;
    },

    adicionarDocumentoItemLocal() {
      this.formularioDocumentoItem = {
        ...this.formularioDocumentoItem,
        descricao: this.formularioDocumentoItem.descricao,
        tipoMovimentoDescricao:
          this.formularioDocumentoItem.tipoMovimento.descricao,
        contaContabilCompleto: `${this.formularioDocumentoItem.planoConta.estrutural} - ${this.formularioDocumentoItem.planoConta.descricao} `,
      };
      this.formulario.documentoContabeisItens.push(
        this.formularioDocumentoItem
      );
      this.limparFormularioDocumentoItem();
    },

    limparFormularioDocumentoItem() {
      this.formularioDocumentoItem = {
        descricao: '',
        planoConta: '',
        valor: '',
        tipoMovimento: '',
      };
    },

    salvarDocumentoItem() {
      this.$store.dispatch(START_LOADING);
      LancamentoContabilService.salvarDocumentoItem({
        ...this.formularioDocumentoItem,
        planoContaId: this.formularioDocumentoItem.planoConta.value.id,
        tipoMovimento: this.formularioDocumentoItem.tipoMovimento.value.id,
        documentoContabilId: this.formulario.id,
      })
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.adicionarDocumentoItemLocal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    editarDocumentoItem(item) {
      item;
      this.edicao = true;
      this.formularioDocumentoItem = {
        ...item.item,
        index: item.index,
        planoConta: item.item.planoConta.value,
        tipoMocumento: item.item.tipoMovimento.value,
        documentoContabilId: this.formulario.id,
      };
    },

    atualizarDocumentoItem() {
      this.$store.dispatch(START_LOADING);
      LancamentoContabilService.atualizarDocumentoItem({
        ...this.formularioDocumentoItem,
        planoContaId: this.formularioDocumentoItem.planoConta.value.id,
        tipoMovimento: this.formularioDocumentoItem.tipoMovimento.value.id,
        documentoContabilId: this.formulario.id,
      })
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.atualizarDocumentoItemLocal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    atualizarDocumentoItemLocal() {
      const index = this.formularioDocumentoItem.index;
      if (
        index >= 0 &&
        index < this.formulario.documentoContabeisItens.length
      ) {
        this.formulario.documentoContabeisItens.splice(
          index,
          1,
          this.formularioDocumentoItem
        );
        this.limparFormularioDocumentoItem();
      }
    },

    // FUNÇÕES DE REMOÇÃO E EXCLUSÃO
    removerDocumentoItem(item) {
      if (!this.formulario.id)
        return this.excluirDocumentoItemLocal(item.index);
      this.confirmarExclusao(item);
    },

    excluirDocumentoItemLocal(index) {
      return this.formulario.documentoContabeisItens.splice(index, 1);
    },

    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('CONTABIL_LANCAMENTO.CONFIRMAR_EXCLUIR_DOCUMENTO_ITEM'),
        this.$t('CONTABIL_LANCAMENTO.CONFIRMAR_EXCLUIR_DOCUMENTO_ITEM_MENSAGEM')
      );
      if (respostaExclusao.value) {
        this.excluirDocumentoItem(item.index);
      }
    },

    excluirDocumentoItem(index) {
      this.$store.dispatch(START_LOADING);
      LancamentoContabilService.excluirDocumentoItem({
        documentoContabilId: this.formulario.id,
        documentoContabilItemId:
          this.formulario.documentoContabeisItens[index].id,
      })
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.excluirDocumentoItemLocal(index);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
