<template>
    <topbar
      :titulo="$t('CONTABIL.LANCAMENTOS_CONTABEIS')"
      :subtitulo="$t('CONTABIL.LANCAMENTOS_CONTABEIS_DESCRICAO_PAGE')"
      :breadcrumbs="breadcrumbs"
    >
      <template #header>
        <div
          class="d-flex flex-md-row flex-column justify-content-between ml-md-4"
        >
          <b-button variant="info" @click="cadastrar">
            {{ $t('GERAL.CADASTRAR') }}
          </b-button>
        </div>
      </template>
    </topbar>
  </template>
  
  <script>
  // Utils & Aux:
  import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
  
  export default {
    components: { Topbar },
    data() {
      return {
        breadcrumbs: [
          { titulo: this.$t('CONTABIL.CONTABIL') },
          { titulo: this.$t('CONTABIL.PERIODOS_CONTABEIS') },
        ],
      };
    },
    methods: {
      cadastrar() {
        this.$emit('cadastrar', 'lancamentoContabil');
      },
    },
  };
  </script>
  