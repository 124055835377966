<template>
  <b-table
    :fields="fields"
    :items="items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    show-empty
    responsive
    striped
    hover
    >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editar(item)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="excluir(item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'tipoMovimentoDescricao',
          label: this.$t('CONTABIL.TIPO_MOVIMENTO'),
          sortable: true,
        },
        {
          key: 'valor',
          label: this.$t('CONTABIL.VALOR_BRL'),
          sortable: true,
        },
        {
          key: 'contaContabilCompleto',
          label: this.$t('CONTABIL.PLANO_CONTA'),
          sortable: true,
        },
           {
          key: 'planoContaCodigo',
          label: this.$t('CONTABIL.PLANO_CONTA_CODIGO'),
          sortable: true,
        },
        {
          key: 'descricao',
          label: this.$t('CONTABIL.DESCRICAO_LANCAMENTO'),
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('CONTABIL.ACOES'),
        },
      ],
    };
  },
  methods: {
    editar(item) {
      return this.$emit('editarDocumentoItem', item);
    },
    excluir(item) {
      return this.$emit('removerDocumentoItem', item);
    },
  },
};
</script>

<style scoped>
div{
  font-size: 12px;
}
</style>
