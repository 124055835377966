<template>
  <div>
    <!-- <div class="row">
      <div class="col-12 col-md-12">
        <cabecalho title="CONTABIL.DOCUMENTO_ORIGEM" title_type="modal" />
        <div class="row">
          <b-col md="auto">
            <input-select-search
              :label="$t('CONTABIL_LANCAMENTO.SELECIONAR_TIPO_CONTA')"
              ref="tipoTituloEnum"
              v-model="form.tipoTituloEnum"
              :options="opcoes.tiposTitulo"
              required
            />
          </b-col>
          <b-col md="auto">
            <input-text
              :label="$t('CONTABIL_LANCAMENTO.DIGITAR_CODIGO')"
              ref="documentoOrigem"
              v-model="form.documentoOrigemCodigo"
              :disabled="filtro.exibir"
              id="documentoOrigem"
            />
          </b-col>
          <b-col md="auto" class="d-flex align-items-center mt-2">
            <span>ou</span>
          </b-col>
          <b-col md="auto">
            <b-button
              variant="info"
              @click="abrirFecharForm"
              class="botao-acao-filtro w-100"
            >
              {{ $t('CONTABIL_LANCAMENTO.BUSCAR_DOCUMENTO_ORIGEM') }}
            </b-button>
          </b-col>
        </div> -->

        <!-- Tipo de filtro -->
        <!-- <div v-if="filtro.exibir" class="bg-light rounded p-3">
          <h6>Filtros para busca</h6>

          <span v-if="form.tipoTituloEnum == ''" class="text-danger">
            {{ $t('CONTABIL.SELECIONE_TIPO_DOCUMENTO') }}
          </span>

          <contas-a-pagar
            v-if="form.tipoTituloEnum == 0"
            @selecionarDocumentoOrigem="selecionarDocumentoOrigem"
          />

          <contas-a-receber
            v-if="form.tipoTituloEnum == 1"
            @selecionarDocumentoOrigem="selecionarDocumentoOrigem"
          />
        </div>
      </div>
    </div>
    <hr /> -->

    <cabecalho title="CONTABIL.OUTRAS_INFORMACOES" title_type="modal" />

    <div class="row">
     <div class="col-12 col-md-6">
        <input-text
          ref="documentoOrigemCodigo"
          v-model="form.documentoOrigemCodigo"
          :label="$t('CONTABIL_LANCAMENTO.DIGITAR_CODIGO')"
          :placeholder="$t('CONTABIL_LANCAMENTO.DIGITAR_CODIGO')"
        />
      </div>
      <div class="col-12 col-md-6">
        <input-text
          ref="objetoReferencia"
          v-model="form.objetoReferenciaId"
          :label="$t('CONTABIL.OBJETO_REFERENCIA')"
          :placeholder="$t('CONTABIL.OBJETO_REFERENCIA')"
        />
      </div>

      <div class="col-12 col-md-6">
        <input-select-search
          ref="categoria"
          v-model="form.categoriaId"
          :label="$t(`CONTABIL.CATEGORIA`)"
          :options="opcoes.categorias"
          required
        />
      </div>
      
      <div class="col-12 col-md-4">
        <input-date-only-btn
          ref="dataMovimento"
          v-model="form.dataMovimento"
          :label="$t('CONTABIL.DATA_MOVIMENTO')"
          required
        />
      </div>

      <div class="col-12 col-md-4">
        <input-date-only-btn
          ref="dataDocumento"
          v-model="form.dataDocumento"
          :label="$t('CONTABIL.DATA_DOCUMENTO')"
          required
        />
      </div>

      <div class="col-12 col-md-4">
        <input-number
          ref="valor"
          v-model="form.valor"
          :label="$t('CONTABIL.VALOR')"
          decimal
        />
      </div>

      <div class="col-12 col-md-6">
        <input-text
          ref="descricaoLancamento"
          v-model="form.descricao"
          :label="$t('CONTABIL.DESCRICAO_LANCAMENTO')"
          :placeholder="$t('CONTABIL.DESCRICAO_LANCAMENTO')"
        />
      </div>

      <div class="col-12 col-md-6">
        <input-text
          ref="marcadoEliminacao"
          v-model="form.marcacaoEliminacaoId"
          :label="$t('CONTABIL.MARCADO_ELIMINACAO')"
          :placeholder="$t('CONTABIL.MARCADO_ELIMINACAO')"
        />
      </div>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import mensagem from '@/common/utils/mensagem';
import { START_LOADING, STOP_LOADING } from '@/store/Store';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

//componentes
import {
  InputDateOnlyBtn,
  InputNumber,
  InputText,
  InputSelectSearch,
} from '@/components/inputs';
// import { Cabecalho } from '@/components/headers';
// import ContasAPagar from '../documento-origem/contas-pagar/Index.vue';
// import ContasAReceber from '../documento-origem/contas-receber/Index.vue';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputDateOnlyBtn,
    InputText,
    InputNumber,
    InputSelectSearch,
    // Cabecalho,
    // ContasAPagar,
    // ContasAReceber,
  },
  watch: {
    'form.categoriaId': 'encontrarDescricaoCategoria',
  },
  data() {
    return {
      opcoes: {
        categorias: [],
        // tiposTitulo: [
        //   { text: 'Contas a pagar', value: '0' },
        //   { text: 'Contas a receber', value: '1' },
        // ],
      },
      // filtro: {
      //   exibir: false,
      // },
    };
  },
  mounted() {
    this.getCategorias();
    this.$emit('refs', this.$refs);
  },
  methods: {
    getCategorias() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('categoria-documento-contabil')
        .then(({ data }) => {
          data = data.map((row) => ({
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.categorias = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    encontrarDescricaoCategoria() {
      if (!this.form.categoriaId) return;
      this.opcoes.categorias.forEach((element) => {
        element.id == this.form.categoriaId ? (this.form.categoria = element.id) : {};
      });
    },
    // funções modal
    abrirFecharForm() {
      this.filtro.exibir == false
        ? (this.filtro.exibir = true)
        : (this.filtro.exibir = false);
    },

    // selecionarDocumentoOrigem(item) {
    //   this.abrirFecharForm();
    //   this.$emit('selecionarDocumentoOrigem', item);
    // },
  },
};
</script>
