import ApiService from '@/common/api/api.service'

let apiBasePath = 'PlanoConta'

const PlanoContaService = {
    // FUNÇÕES CRUD:
    async criar (form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form)
        return data
    },
    async editar (form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form)
        return data
    },
    async excluir (itemId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${itemId}`)
        return data
    },
    // FUNÇÕES IMPRIMIR:
    async imprimirPlanoContas () {
        let { data } = await ApiService.get(`${apiBasePath}/imprimir-plano-contas`)
        return data
    },
    // FUNÇÕES GET:
    async obterPorId (itemId) {
        let { data } = await ApiService.get(`${apiBasePath}/${itemId}`)
        return data
    },
    async obterTodos () {
        let { data } = await ApiService.get(`${apiBasePath}/obter-todos`)
        return data
    },
    async obterTodosPaginado (paginacao) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-paginado`, {
            params: {
                TamanhoDaPagina: paginacao.tamanhoDaPagina,
                NumeroDaPagina: paginacao.numeroDaPagina,
            }
        })
        return data
    }
}

export default PlanoContaService