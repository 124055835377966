<template>
  <div>
    <titulo-lancamentos-contabeis @cadastrar="openModal" />

    <filtro-lancamentos @filtrar="getDocumentoContabil" />

    <tabela-documentos
      :items="items"
      @editar="openModal"
      @excluir="confirmarExclusao"
    />

    <paginacao
      :total="total"
      v-model="paginacao"
      @input="getDocumentoContabil(filtro)"
    />

    <modal-lancamentos-contabeis
      :exibir="modais.lancamentoContabil"
      :form="modais.lancamentoContabilDados"
      @fechar="closeModal"
      @atualizarTabela="getDocumentoContabil"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import modais from '@/common/utils/modais';
import mensagem from '@/common/utils/mensagem';

// Services:
import DocumentoContabil from '@/common/services/lancamento-contabil/lancamento-contabil.service';

//Components:
import FiltroLancamentos from './components/Filtro';
import TabelaDocumentos from './components/Tabela';
import ModalLancamentosContabeis from './components/modal/Index';
import Paginacao from '@/components/paginacao/Paginacao';
import TituloLancamentosContabeis from '@/views/contabil/lancamentos/components/TituloLancamentosContabeis.vue';

export default {
  components: {
    TituloLancamentosContabeis,
    FiltroLancamentos,
    TabelaDocumentos,
    Paginacao,
    ModalLancamentosContabeis,
  },
  data() {
    return {
      items: [],
      modais: {
        lancamentoContabil: false,
      },
      filtro: {
        documento: '',
      },
      total: 0,
      paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
    };
  },
  methods: {
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },

    closeModal() {
      return (this.modais.lancamentoContabil = false);
    },

    // OBTER DADOS
    getDocumentoContabil(documento = '') {
      if (documento?.length > 0) this.filtro.documento = documento;
      this.$store.dispatch(START_LOADING);
      DocumentoContabil.obterPor(this.filtro, this.paginacao)
        .then(({ data }) => {
          this.items = data.itens;
          this.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('CONTABIL_LANCAMENTO.CONFIRMAR_EXCLUIR_DOCUMENTO'),
        this.$t('CONTABIL_LANCAMENTO.CONFIRMAR_EXCLUIR_DOCUMENTO_MENSAGEM')
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(item);
      }
    },

    excluir(item) {
      this.$store.dispatch(START_LOADING);
      DocumentoContabil.excluir(item.id)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.getDocumentoContabil();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
